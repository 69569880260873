@import url('https://fonts.cdnfonts.com/css/arco');

body {
  margin: 0;
  font-family: 'ARCO', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

@font-face {
  font-family: 'Now Regular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Regular'), url('./fonts/Now-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Now Thin';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Thin'), url('./fonts/Now-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Now Light';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Light'), url('./fonts/Now-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Now Medium';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Medium'), url('./fonts/Now-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Now Bold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Bold'), url('./fonts/Now-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Now Black';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Now Black'), url('./fonts/Now-Black.woff') format('woff');
  }