@media screen and (max-width: 1550px) {
  .navbar > .body-list {
    font-size: 1rem;
  }

  .navbar > .icons {
    margin-top: 5px;
    scale: 0.6;
  }

  .reviews .banner > h2, h3{
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1350px) {
  .navbar > .body-list {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1200px) {
  .navbar > .body-list li{
    padding: 0 1.5rem;
  }

  .info {
    padding: 80px 50px 50px 50px
  }
}

@media screen and (max-width: 1100px) {

  .navbar > .logo {
    position: absolute;
    left: 0;
    scale: 1;
  }

  .navbar > .body-list{
    display: none;
  }

  .icons {
    top: 5px;
  }

  .info {
    flex-direction: column;
    padding: 80px 0 30px 0;
  }

  .info-text {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }

  .reviews .banner {
    width: 30%;
    padding: 0 10px;
  }
  
  .reviews .banner > h2, h3{
    font-size: 0.8rem;
  }

  .reviews .banner h2 {
    padding: 10px 5px;
  }

  .about > .pricing-price {
    font-size: 1rem;
    margin: 0 25% 20px 25%;
  }
}

@media screen and (max-width: 920px) {
  .head > .news {
    right: 5%;
    top: 40%;
  }
  
  .welcome {
        grid-template: 15vh auto 15vh / 10vw 50vw 40vw;
        font-size: 0.8rem;
      }

    .welcome img:nth-child(2) {
        height: 100%;
        width: 100%;
    }

    .welcome > div button {
        scale: 0.8;
    }

    .body-main {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 15vh;
        color: var(--mud);
        background: linear-gradient(var(--mud) 10%, var(--slime)10% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 70%, var(--leaves) 70% 80%, var(--slime) 80% 90%, var(--mud) 90% 100%);
      }
      
      .body-main img {
        height: 10vh;
      }
      
      .body-main img:nth-child(2) {
        height: 20vh;
        background-color: var(--mud);
        border-radius: 20%;
        padding: 10px 5px 20px 5px;
        border: var(--leaves) 5px solid;
      }

      .main-footer {
        grid-template: 10vh auto / repeat(2, 33vw);
        margin: 10vh 15%;
      }

      .main-footer > .event img {
        height: 200px;
      }

      .main-footer > .event > .event-title {
        height: 50%;
      }

  .locations > .darley > .darley-address {
    padding-bottom: 3vh;
    line-height: 2;
  }

  .locations > .darley > .darley-address button {
    width: 17vw;
  }

  .electric-daisy > .daisy > .daisy-address {
    padding-bottom: 3vh;
    line-height: 2;
  }

  .electric-daisy > .daisy > .daisy-address button {
    width: 20vw;
  }
}

@media screen and (max-width: 850px) {
  
  .locations > .muddler-img img{
    width: 35vw;
  }

  .electric-daisy > .daisy-img img {
    width: 100%;
  }

  .party-menu > .party-blurb {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .party-menu img {
    height: 14rem;
    width: 14rem;
  }
}


@media screen and (max-width: 800px) {
  .details{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dropdown-menu a{
    font-size: 3rem;
  }

  .reviews{
    flex-direction: column;
    align-items: center;
  }

  .reviews .banner{
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .navbar > .logo {
    position: absolute;
    left: 0;
    scale: 0.6;
  }
  
  .navbar > .icons {
    margin-top: 55px;
    scale: 0.7;
    right: 0;
  }

  .welcome {
    font-size: 0.7rem;
  }

  .banner {
    padding: 0 10px;
  }
  
  .banner > h2, h3{
    font-size: 1rem;
  }

  .banner h2 {
    padding: 10px 5px;
  }

  .info-blurb {
    padding-bottom: 20px;
    text-align: center;
  }
  
  .about > .pricing-price {
    font-size: 1rem;
    margin: 0 12% 20px 12%;
  }

  .about > .staff > .staff-img {
    padding: 0;
  }

  .about > .staff > .staff-img img {
    height: 225px;
    width: 168px;
  }
}

@media screen and (max-width: 580px) {

.head > .news img {
    width: 200px;
    height: 200px;
  }

.icons {
  top: -55px;
}

.dropdown-menu a{
  font-size: 1.8rem;
}

.dropdown-menu > .close-menu button{
  right: 10px;
}

  .banner {
    padding: 0 10px;
  }
  
  .banner > h2, h3{
    font-size: 0.9rem;
  }

  .banner h2 {
    padding: 10px 5px;
  }

  .dropdown-menu {
    font-size: 3rem;
  }
  
    .mobile-list h3{
      font-size: 1rem;
    }

    .welcome {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding-bottom: 50px;
    }

    .welcome h2 {
        font-size: 1rem;
    }

    .welcome > .para {
      padding: 2vh 7vw 5vh 3vw;
    }
    
    .welcome img {
      position: relative;
      width: 100%;
      border-radius: 0 0 0 100%;
    }

    .welcome > div button {
        scale: 0.7;
    }

    .welcome > .sidebar {
        display: none;
    }

    .body-main {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 20vh;
        color: var(--mud);
        background: linear-gradient(var(--mud) 10%, var(--slime)10% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 70%, var(--leaves) 70% 80%, var(--slime) 80% 90%, var(--mud) 90% 100%);
      }
      
      .body-main img {
        height: 5vh;
      }
      
      .body-main img:nth-child(2) {
        height: 20vh;
        background-color: var(--mud);
        border-radius: 20%;
        padding: 10px 5px 20px 5px;
        border: var(--leaves) 5px solid;
      }

    .main-footer {
        display: flex;
        flex-direction: column;
    }

    .main-footer > .event {
        padding: 0 0 40px 0;
        width: 70%;
    }

    .main-footer > .event button {
      scale: 0.7;
    }

    .headline img {
      height: 10vh;
    }

  .about > .blurb {
    flex-direction: column;
  }

  .about > .blurb > .blurb-intro {
    width: 80vw;
    padding: 0 5vw;
  }

  .about > .blurb > .blurb-benefits {
    width: 80vw;
    padding: 0 5vw;
  }

  .about > .blurb > .blurb-intro-img img:nth-child(1) {
    display: none;
  }

  .about > .blurb > .blurb-intro-img img{
    width: 60vw;
  }

  .about > .blurb > .blurb-benefits-img img{
    width: 60vw;
  }

  .blurb-benefits button {
    display: none;
  }

  .about > .staff {
    flex-direction: column;
  }

  .about > .staff .staff-img img {
    width: 80%;
  }

  .about > .profile {
    flex-direction: column;
  }

  .about > .profile .profile-img img {
    width: 80%;
  }

  

  .locations {
    flex-direction: column;
  }

  .locations > .muddler-img {
    background: linear-gradient(var(--mud) 1%, var(--slime)1% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 80%, var(--leaves) 80% 91%, var(--flowers) 91% 94%, var(--slime) 94% 97%, var(--mud) 97% 100%);
    height: 55vh;
    width: 100vw;
  }

  .locations > .muddler-img img{
    height: 50vh;
    width: 100vw;
  }

  .locations .darley {
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    padding-bottom: 3vh;
  }

  .locations > .darley > .darley-title {
    padding: 3vh 14vw 0 14vw;
  }

  .locations > .darley > .darley-blurb {
    padding: 0vh 14vw;
  }
  
  .locations > .darley .darley-img img {
    width: 100%;
  }

  .locations > .darley > .darley-address {
    padding: 0 14vw;
  }
  
  .locations > .darley > .darley-address button {
    width: 30vw;
  }
  
  .darley-gallery {
    display: flex;
    width: 100vw;
    border-top: var(--mud) 10px solid;
  }
  
  .darley-gallery img{
    width: 15.85%;
    padding: 4px;
    background-color: var(--leaves);
  }

  .electric-daisy > .muddler-img {
    position: absolute;
    background: linear-gradient(var(--mud) 1%, var(--slime)1% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 80%, var(--leaves) 80% 91%, var(--flowers) 91% 94%, var(--slime) 94% 97%, var(--mud) 97% 100%);
    height: 55vh;
    width: 100vw;
  }

  .electric-daisy > .muddler-img img{
    padding-left: 0;
    height: 50vh;
    width: 100vw;
  }

  .electric-daisy > .daisy {
    display: flex;
    flex-direction: column;
    width: 97vw;
    padding: 50vh 0 3vh 0;
  }

  .electric-daisy > .daisy > .daisy-title {
    padding: 3vh 14vw 0 14vw;
  }

  .electric-daisy > .daisy > .daisy-blurb {
    padding: 0 14vw;
  }

  .electric-daisy > .daisy > .daisy-address {
    padding: 0 14vw;
  }

  .electric-daisy > .daisy > .daisy-address button {
    width: 30vw;
  }

  .party > .party-blurb {
    width: 70vw;
    margin-left: 10vw;
  }

  .form {
    width: 75%;
  }

  .form > form {
    align-items: center;
  }

  .form > form > button {
    width: 120px;
  }

  .party-menu img {
    height: 12rem;
    width: 12rem;
  }
}

@media screen and (max-width: 300px) {

  .dropdown-menu {
    font-size: 2.6rem;
  }

  .mobile-list h3{
    font-size: 1rem;
  }

  .party-menu > .party-blurb button {
    width: 140px;
    height: 45px;
  }

  .party-menu img {
    height: 8rem;
    width: 8rem;
  }
}

