:root {
  --mud: #533f4b;
  --leaves: #e5c684;
  --flowers: #f9b5b2;
  --slime: #c2c5a2;
  --clouds: #fff;
}

.App {
  overflow: hidden;
}

.head {
  background-image: url('./images/lead.webp');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 75vh;
  border-bottom: solid 10px var(--mud);
}

.head > .news {
  position: absolute;
  right: 10%;
  top: 20%;
  background: transparent;
  border: none;
}

.head > .news img {
  width: 300px;
  height: 300px;
}

.head > .news img:hover {
  cursor: pointer;
}

.App-about-header {
  background-image: url('./images/headline.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 70vh;
}

.App-locations-header {
  background-image: url('./images/hut.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 70vh;
}

.App-parties-header {
  background-image: url('./images/happyb.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 70vh;
}

.dropdown-menu {
  display: flex;
}

.dropdown-menu > .close-menu button{
  position: absolute;
  top: 30px;
  right: 50px;
  color: var(--mud);
  background-color: transparent;
  font-size: 2rem;
  border: none;
  font-family: "ARCO";
  cursor: pointer;
  z-index: 10;
}

.dropdown-menu ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
  z-index: 5;
}

.dropdown-menu a{
  display: flex;
  height: 16.6vh;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  list-style-type: none;
  border-top: solid 2px var(--mud);
  font-size: 5rem;
}

.dropdown-menu a:nth-child(1) {
  background-color: var(--clouds);
  color: var(--flowers);
}

.dropdown-menu a:nth-child(2) {
  background-color: var(--slime);
  color: var(--mud);
}

.dropdown-menu a:nth-child(3) {
  background-color: var(--flowers);
  color: var(--clouds);
}

.dropdown-menu a:nth-child(4) {
  background-color: var(--clouds);
  color: var(--flowers);
}

.dropdown-menu a:nth-child(5) {
  background-color: var(--leaves);
  color: var(--mud);
}

.dropdown-menu a:nth-child(6) {
  background-color: var(--mud);
  color: var(--flowers);
}

.navbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Now Bold";
  font-size: 1.5rem;
  width: 100%;
  background-color: rgba(83, 63, 75, 1);
}

.body-list ul{
  display: flex;
  padding-right: 100px;
  padding-left: 280px;
}
.body-list li {
  display: block;
  padding: 0 2.2rem;
}

.icons {
  position: fixed;
  right: 20px;
  z-index: 10;
}

.welcome {
  display: grid;
  grid-template: 15vh auto 30vh / 10vw 60vw 30vw;
  line-height: 1.8;
}

.welcome h2 {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  font-family: "Now Bold";
  background-color: var(--leaves);
  margin: 0;
  padding: 5vh 0 5vh 5vw;
}

.welcome > .para{
  display: flex;
  flex-direction: column;
  font-family: 'Now Regular';
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  padding: 2vh 5vw 5vh 5vw;
  font-size: 1rem;
}

.welcome > .para li {
  padding-top: 8px;
  list-style: none;
}

.welcome img {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
  height: 100%;
  width: 30vw;
  border-bottom-left-radius: 100%;
  border-left: var(--slime) 10px solid;
  border-bottom: var(--slime) 10px solid;
  z-index: 1;
}

.welcome > .sidebar {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
}

.welcome > .sidebar img {
  border-bottom-left-radius: 0;
  border-left: none;
  border-bottom: none;
}

.welcome > div {
  display: flex;
  justify-content: flex-end;
}

.welcome > div button {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  width: 260px;
  height: 80px;
  background-color: var(--mud);
  border: 3px solid var(--leaves);
  border-radius: 40px;
  font-family: "Now Bold";
  font-size: 1rem;
}

.welcome > div button a {
  color: var(--leaves);
}

.welcome button:hover {
  box-shadow: var(--leaves) 2px 2px 10px;
  cursor: pointer;
}

.body-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30vh;
  color: var(--mud);
  background: linear-gradient(var(--mud) 10%, var(--slime)10% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 70%, var(--leaves) 70% 80%, var(--slime) 80% 90%, var(--mud) 90% 100%);
  font-size: 2rem;
}

.body-main img {
  height: 40vh;
  z-index: 2;
}

.body-main img:nth-child(2) {
  height: 40vh;
  background-color: var(--mud);
  border-radius: 20%;
  padding: 10px 5px 20px 5px;
  border: var(--leaves) 5px solid;
}

.main-footer {
  display: grid;
  place-items: center;
  grid-template: 20vh auto / repeat(2, 33vw);
  margin: 10vh 15%;
}

.main-footer h3{
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  font-size: 2rem;
  color: var(--mud);
}

.main-footer > .event {
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Now Regular";
}

.main-footer > .event img{
  width: 80%;
  height: 50vh;
  margin: 5% 5%;
  border-radius: 20%;
  border: 10px var(--slime) solid;
}

.main-footer > .event > .event-title {
  width: 100%;
  line-height: 0.5;
}

.main-footer > .event > .event-title h4 {
  text-align: center;
}

.main-footer > .event > .event-title p{
  font-size: 15px;
}

.main-footer > .event p {
  padding: 0 10%;
  text-align: center;
}

.main-footer > .event button {
  border: none;
  background-color: var(--mud);
  color: var(--clouds);
  font-weight: 700;
  border: var(--leaves) solid 3px;
  border-radius: 40px;
  width: 200px;
  height: 50px;
  align-self: center;
}

.main-footer > .event button a{
  color: var(--clouds);
  text-decoration: none;
}

.main-footer > .event button:hover {
  box-shadow: var(--leaves) 1px 1px 10px;
  cursor: pointer;
}

.headline {
  background: linear-gradient(var(--mud) 10%, var(--slime)10% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 70%, var(--leaves) 70% 80%, var(--slime) 80% 90%, var(--mud) 90% 100%);
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headline img {
  height: 20vh;
  background-color: var(--mud);
  border-radius: 100%;
  border-bottom: var(--mud) solid 30px;
  border-top: var(--mud) solid 15px;
  z-index: 1;
}

/* About */

.about {
  font-family: "Now Regular";
  line-height: 1.6;
}

.about > .blurb {
  margin: 10vh 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
}

.about > .blurb > .blurb-intro {
  display: flex;
  flex-direction: column;
  width: 55vw;
  padding: 0 5vw;
}

.about > .blurb > .blurb-intro-img img:nth-child(1) {
  position: absolute;
  height: 300vh;
  width: 5vw;
  top: 50vh;
  right: 15vw;
  border: none;
  z-index: -1;
}

.about > .blurb > .blurb-intro-img {
  background-color: var(--flowers);
  padding: 20px 0;
  border-radius: 40px;
}

.about > .blurb > .blurb-intro-img img{
  width: 30vw;
  border-radius: 20px;
  border-top: var(--leaves) 15px solid;
  border-bottom: var(--leaves) 15px solid;
  border-right: var(--leaves) 7px solid;
  border-left: var(--leaves) 7px solid;
}

.about > .blurb > .blurb-benefits {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  width: 55vw;
  padding: 0 5vw;
}

.about > .blurb > .blurb-benefits h4{
  font-family: 'Now Bold';
  font-size: 1.5rem;
}

.about > .blurb > .blurb-benefits-img {
  background-color: var(--leaves);
  padding: 20px 0;
  border-radius: 40px;
}

.about > .blurb > .blurb-benefits-img img{
  width: 30vw;
  border-radius: 40px;
  border-top: var(--slime) 15px solid;
  border-bottom: var(--slime) 15px solid;
  border-right: var(--slime) 7px solid;
  border-left: var(--slime) 7px solid;
}

.about > .pricing-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  margin: 0 35% 20px 35%;
  border: 5px var(--mud) solid;
  border-radius: 50px;
}

.about > .pricing p {
  padding: 0 2%;
}

.blurb-benefits button {
  width: 200px;
  height: 50px;
  align-self: end;
  background-color: var(--mud);
  border: 3px solid var(--leaves);
  border-radius: 40px;
  font-family: "Now Bold";
  font-size: 1rem;
}

.about > .staff {
  display: flex;
  justify-content: space-between;
  padding: 10vh 7vw;
  border-top: var(--mud) solid 10px;
  background: var(--clouds);
}

.about > .staff > .staff-img {
  display: flex;
  justify-content: center;
  background: radial-gradient(var(--mud) 50%, var(--flowers)50% 60%, var(--slime) 60% 70%, var(--mud) 70% 80%, var(--leaves) 80% 85%, var(--slime) 85% 100%, var(--mud) 90% 100%);
  border-radius: 50%;
  rotate: 10deg;
  padding: 40px;
}

.about > .staff > .staff-img img {
  height: 500px;
  width: 375px;
  border-radius: 50%;
  rotate: -15deg;
}

.about > .staff > .staff-blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3vw;
}

.about > .profile {
  display: flex;
  justify-content: space-between;
  padding: 10vh 7vw;
  border-top: var(--mud) solid 10px;
  background-color: var(--clouds);
}

.about > .profile > .profile-img {
  display: flex;
  justify-content: center;
  background: radial-gradient(var(--mud) 50%, var(--slime)50% 60%, var(--leaves) 60% 70%, var(--mud) 70% 80%, var(--leaves) 80% 85%, var(--slime) 85% 100%, var(--mud) 90% 100%);
  border-radius: 50%;
  rotate: -10deg;
}

.about > .profile > .profile-img img {
  padding-top: 10%;
  height: 70%;
  width: 40vw;
  border-radius: 50%;
  rotate: 15deg;
}

.about > .profile > .profile-blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 3vw;
}

/* Locations - Darley Abbey*/

.locations {
  font-family: 'Now Regular';
  display: flex;
  line-height: 1.6;
}

.locations > .muddler-img {
  background: linear-gradient(90deg, var(--mud) 1%, var(--slime)1% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 80%, var(--leaves) 80% 91%, var(--flowers) 91% 94%, var(--slime) 94% 97%, var(--mud) 97% 100%);
  width: 35vw
}

.locations > .muddler-img img{
  height: 100%;
  width: 30vw;
}

.locations > .darley {
  display: grid;
  grid-template: repeat(3, auto) / 45vw 20vw;
}

.locations > .darley > .darley-title {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  padding: 10vh 0 0 3vw;
  font-family: 'Now Bold';
  font-size: 1.6rem;
}

.locations > .darley > .darley-blurb {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
  padding: 0 3vw;
}

.locations > .darley .darley-img {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.locations > .darley .darley-img img {
  width: 100%;
}

.locations > .darley > .darley-address {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  padding-left: 1vw;
  line-height: 1;
}

.locations > .darley > .darley-address p{
  font-size: 0.75rem;
}

.locations > .darley > .darley-address button {
  width: 10vw;
  height: 6vh;
  border: none;
  color: var(--leaves);
  background-color: var(--mud);
  border-radius: 40px;
  font-family: "Now Bold";
  font-size: 0.7rem;
}

.locations > .darley > .darley-address button:hover {
  cursor: pointer;
  box-shadow: var(--leaves) 1px 1px 10px;
}

.darley-gallery {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: var(--mud) 10px solid;
}

.darley-gallery img{
  width: 16.5%;
  padding: 4px;
  background-color: var(--leaves);
}

/* Locations - Electric Daisy */

.electric-daisy {
  font-family: 'Now Regular';
  display: flex;
  line-height: 1.6;
}

.electric-daisy > .muddler-img {
  background: linear-gradient(90deg, var(--mud) 4%, var(--slime) 4% 8%, var(--flowers)8% 12%, var(--leaves) 12% 25%, var(--flowers) 27% 75%, var(--leaves) 75% 90%, var(--flowers) 90% 95%, var(--mud) 95% 100%);
  width: 35vw
}

.electric-daisy > .muddler-img img{
  padding-left: 5vw;
  height: 100%;
  width: 30vw;
}

.electric-daisy > .daisy {
  display: grid;
  grid-template: repeat(3, auto) / 20vw 45vw;
}

.electric-daisy > .daisy > .daisy-title {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  padding: 10vh 0 0 3vw;
  font-family: "Now Bold";
  font-size: 1.6rem;
}

.electric-daisy > .daisy > .daisy-blurb {
  grid-row: 2 / 4;
  grid-column: 2 / 3;
  padding: 0 3vw;
}

.electric-daisy > .daisy > .daisy-img {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.electric-daisy > .daisy > .daisy-img img {
  width: 100%;
}

.electric-daisy > .daisy > .daisy-address {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  padding-left: 1vw;
  line-height: 1;
}

.electric-daisy > .daisy > .daisy-address p{
  font-size: 0.9rem;
}

.electric-daisy > .daisy > .daisy-address button {
  width: 10vw;
  height: 6vh;
  border: none;
  color: var(--leaves);
  background-color: var(--mud);
  border-radius: 40px;
  font-family: "Now Bold";
  font-size: 0.7rem;
}

.electric-daisy > .daisy > .daisy-address button:hover {
  cursor: pointer;
  box-shadow: var(--leaves) 1px 1px 10px;
}

.daisy-gallery {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: var(--mud) 10px solid;
}

.daisy-gallery img{
  width: 16.5%;
  padding: 4px;
  background-color: var(--leaves);
} 

/* Parties */

.party {
  background-image: url(./images/party-bckgrd.png);
  background-size: cover;
  background-position: center;
  font-family: "Now Regular";
  height: 100%;
  width: 100vw;
  padding-bottom: 15vh;
}

.party-img {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  margin: auto 0;
}

.party-img img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: var(--slime) solid 10px;
}

.party-img img:nth-child(2){
  position: absolute;
  right: 0;
  margin-top: 50vh;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: var(--slime) solid 10px;
  z-index: -1;
}

.party > .party-blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--clouds);
  width: 45vw;
  margin-left: 20vw;
  padding: 0 5vw 5% 5vw;
  border: var(--mud) 4px solid;
}

.party > .party-blurb button {
  width: 160px;
  height: 50px;
  background-color: var(--mud);
  color: var(--leaves);
  border-radius: 40px;
  font-family: 'Now Regular';
}

.party > .party-blurb button:hover {
  box-shadow: var(--leaves) 1px 1px 8px;
  cursor: pointer;
}

.party-blurb > .party-head {
  font-family: 'ARCO';
  text-align: center;
}

.party-blurb p {
  line-height: 1.6;
}

.party-menu > .party-blurb {
  display: flex;
  justify-content: space-between;
  background-color: var(--clouds);
  width: 45vw;
  margin-left: 20vw;
  padding: 5vh 5vw 5% 5vw;
  border-bottom: var(--mud) 4px solid;
  border-left: var(--mud) 4px solid;
  border-right: var(--mud) 4px solid;
}

.party-menu > .party-blurb button {
  width: 160px;
  height: 50px;
  background-color: var(--mud);
  color: var(--leaves);
  border-radius: 40px;
  font-family: 'Now Regular';
}

.party-menu > .party-blurb button:hover {
  box-shadow: var(--leaves) 1px 1px 8px;
  cursor: pointer;
}

.party-menu > .party-blurb button a{
  color: var(--clouds);
  text-decoration: none;
}

.party-menu > .party-blurb > .party-head {
  font-family: 'ARCO';
  text-align: center;
}

.party-menu > .party-blurb p {
  line-height: 1.6;
}

.party-menu img {
  height: 18rem;
  width: 18rem;
}

.party-gallery {
  display: flex;
  width: 100vw;
  border-top: var(--mud) 10px solid;
}

.party-gallery img{
  width: 15.85%;
  padding: 4px;
  background-color: var(--leaves);
} 

.contact img{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
}

/* Contact */

.contact-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  background-image: url('./images/bw-bckground.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.contact {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent 35%, var(--mud) 35% 36.5%, var(--slime) 36.5% 40%, var(--leaves) 40% 45%, var(--flowers) 45% 55%, var(--leaves) 55% 60%, var(--slime) 60% 63.5%, var(--mud)63.5% 65%, transparent 65% 100%);
}

.form {
  background-color: var(--slime);
  width: 60%;
  border: var(--mud) solid 5px;
  font-family: 'Now Regular';
  border-radius: 40px;
}

.form h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.form > form {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
}

.form > form > input {
  box-sizing: border-box;
  border: #000 2px solid;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: var(--leaves);
  border-radius: 40px;
}

.form > form > textarea {
  box-sizing: border-box;
  height: 150px;
  border: #000 2px solid;
  margin: 5px 0;
  padding: 10px;
  background-color: var(--flowers);
  border-radius: 20px;
}

.form > form > button {
  width:20%;
  height: 30px;
  margin: 10px 40%;
  background-color: var(--mud);
  color: #f6f5f5;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  font-family: 'Now Regular';
}

.form > form > button:hover {
  border: var(--leaves) solid 2px;
}

.review-border{
  background: linear-gradient(var(--mud) 10%, var(--slime)10% 20%, var(--leaves) 20% 27%, var(--flowers) 27% 70%, var(--leaves) 70% 80%, var(--slime) 80% 90%, var(--mud) 90% 100%);
  height: 5vh;
}

.reviews{
  display: flex;
  width: 100%;
}

.reviews .banner {
  display: flex;
  flex: 1;
  height: 50vh;
  width: 28%;
  flex-direction: column;
  justify-content:center;
  background: var(--mud);
  margin: 5vh 5vw;
  border-radius: 20px;
  border: var(--leaves) 2.5px solid
}

.reviews .banner > h2, h3{
  font-family: 'Now Thin';
  font-size: 1.2rem;
  color: var(--clouds);
  text-align: center;
}

.reviews .banner h2 {
  border-top: var(--leaves) 2px solid;
  border-bottom: var(--leaves) 2px solid;
  padding: 10px 10px;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Now Regular', sans-serif;
  padding: 50px;
}

.info-menu {
  padding-right: 50px;
}

.info-blurb p {
  line-height: 1.6;
}

.castle {
  display: flex;
  justify-content: center;
}

.castle img {
  width: 200px;
}

.footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15vh;
  background-color: var(--slime);
  border-top: solid 10px var(--mud);
  font-family: 'Now-Bold';
}

/* Admin */

.admin {
  font-family: 'Now Regular', sans-serif;
  border-bottom: rgba(82, 82, 82, 0.2) solid 5px;
  background-color: #ddecfa;
  display: flex;
  align-items: center;
  height: 80px;
}

.admin h1 {
  padding-left: 40px;
}

/* Login */

.login {
  font-family: 'Now Regular', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ddd;
}